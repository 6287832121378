/* eslint-disable no-shadow */
export enum DatabaseCollection {
  Users = 'Users',
  Assets = 'Assets',
}

export enum DatabaseSubCollection {
  Feels = 'Feels',
  Journal = 'Journal',
}
