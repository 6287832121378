export const MONTHS = [
  {
    name: 'Январь',
    index: 0,
  },
  {
    name: 'Февраль',
    index: 1,
  },
  {
    name: 'Март',
    index: 2,
  },
  {
    name: 'Апрель',
    index: 3,
  },
  {
    name: 'Май',
    index: 4,
  },
  {
    name: 'Июнь',
    index: 5,
  },
  {
    name: 'Июль',
    index: 6,
  },
  {
    name: 'Август',
    index: 7,
  },
  {
    name: 'Сентябрь',
    index: 8,
  },
  {
    name: 'Октябрь',
    index: 9,
  },
  {
    name: 'Ноябрь',
    index: 10,
  },
  {
    name: 'Декабрь',
    index: 11,
  },
];
